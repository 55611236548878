import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'app/api-services/api.service';
import { MetaService } from 'app/meta.service';
import { trainerInterface } from 'app/pages/commonComponents/customInterfaces/customInterfaces.interface';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-plan-time-trainer',
  templateUrl: './change-plan-time-trainer.component.html',
  styleUrls: ['./change-plan-time-trainer.component.scss']
})
export class ChangePlanTimeTrainerComponent {

  title: string = "Update Plan Time & Trainer"


  selectedTimeSlot = null
  allowedTimeSlotObjs = []

  selectedTrainer: trainerInterface | null = null
  allowedTrainerObjs: trainerInterface[] = []
  filteredTrainerObjs: trainerInterface[] = []

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      public dialogRef: MatDialogRef<ChangePlanTimeTrainerComponent>,
      public meta: MetaService,
      public apiService: ApiService, 
      public toastr: ToastrService, 
    ){
      this.getAllAvailableTimeSlotToUpdateTimeandTrainer()
    }

    ngOnInit(){
      this.meta.dialogClosed = false
    }

    getAllAvailableTimeSlotToUpdateTimeandTrainer(){

      let userOrderPlanId: string = this.data.userOrderPlanId  
      this.apiService.getAllAvailableTimeSlotsToUpdateTimeandTrainer(userOrderPlanId=userOrderPlanId).subscribe((response) => {
        if (response.status == "success") {
          this.allowedTimeSlotObjs = response.data.timeslots
        }
      },
      (err) => {
          this.toastr.error(err.error.message)
        }
      );
    }

    applyFilterOnTrainers(searchValue){
      this.filteredTrainerObjs = []
      if(this.selectedTrainer){
        this.filteredTrainerObjs = [this.selectedTrainer]
      }
      this.filteredTrainerObjs = this.filteredTrainerObjs.concat(
        this.allowedTrainerObjs.filter(optionObj => 
          optionObj.full_name.toLowerCase().includes(searchValue.toLowerCase()) && this.selectedTrainer != optionObj
      ))
    }

    getAllAvailableTimeSlotTrainersToUpdateTimeandTrainer(){
      let userOrderPlanId: string = this.data.userOrderPlanId
      let timeSlot = this.selectedTimeSlot["alias"]
      this.apiService.getAllAvailableTimeSlotTrainersToUpdateTimeandTrainer(userOrderPlanId=userOrderPlanId, timeSlot=timeSlot).subscribe((response) => {
        if (response.status == "success") {
          this.allowedTrainerObjs = response.data.trainers;
          this.filteredTrainerObjs = response.data.trainers;
        }
      },
      (err) => {
          this.toastr.error(err.error.message)
        }
      );
    }

    updatePlanTimeTrainer(){
      let userOrderPlanId: string = this.data.userOrderPlanId
      let timeSlot: string = this.selectedTimeSlot["alias"]
      let trainerId: string = this.selectedTrainer["id"]
  
      this.apiService.updatePlanTimeTrainerAPI(userOrderPlanId=userOrderPlanId, trainerId=trainerId, timeSlot=timeSlot).subscribe((response) => {
        if (response.status == "success") {
          this.toastr.success(response.message)
        }

        this.meta.dialogClosed = true;
        this.dialogRef.close()

      },
      (err) => {
          this.toastr.error(err.error.message)
        }
      );
    }

}
