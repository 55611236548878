<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">

        <div class="row mt-8 justify-content-between">
            <div class="col-sm-12 col-lg-8 mt-8 d-flex flex-column flex-sm-row">
                <div class="mr-2" *ngIf="userId">
                    <button mat-icon-button (click)="back()" >
                        <mat-icon class="mt-3" style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                    </button>
                </div>
                <h1 class="h3 ml-1 mr-6 heading">{{userName}}</h1>
            </div>

            <div class="col-xs-12 col-sm-6 col-md-4  mb-2 d-flex justify-content-end">
                <button class="addBtn m-1" style="width: 170px" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'"
                  (click)="onClickDownload()">
                  <mat-icon class="white-icon" [svgIcon]="'mat_outline:file_download'"></mat-icon>
                  <span class="ml-2 mr-1 txt" style="color: white">Purchase Report</span>
                </button>
              </div>
        </div>

        <div class="row">

            <div class="col-sm-12 col-md-4 col-lg-2 mb-2" *ngIf="isUserAllowedToViewBranches">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                  <mat-select [placeholder]="'All Branches'" [(ngModel)]="filterByBranchId" (selectionChange)="onGetUserPurchaseHistory()">
                    <mat-option value="">All Branches</mat-option>
                    <mat-option *ngFor="let branch of branches_list" [value]="branch.id">{{branch.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                  <mat-select  [(ngModel)]="filterByPaymentStatus"  [placeholder]="'Payment Status'"(selectionChange)="onGetUserPurchaseHistory()">
                    <mat-option *ngFor="let paymentStatus of paymentStatuses" [value]="paymentStatus.value">{{paymentStatus.viewValue}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            <div class="col-sm-12 col-md-4 col-lg-2 mb-2" >
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                    <input matInput readonly [matDatepicker]="picker" [(ngModel)]="filterByFromDate"
                        [placeholder]="'Payment From Date'" (dateChange)="onGetUserPurchaseHistory()">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-4 col-lg-2 mb-2" >
                <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;">
                    <input matInput readonly [matDatepicker]="picker1" [(ngModel)]="filterByToDate"
                        [placeholder]="'Payment To Date'" (dateChange)="onGetUserPurchaseHistory()">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
            
            <div class="col-sm-12 col-md-4 col-lg-2 mb-2" *ngIf="searchIndicator">
                <mat-form-field
                    class="flex-auto gt-xs:pr-3" style="width: 100%;"
                    [subscriptSizing]="'dynamic'"
                >
                    <mat-icon
                        class="icon-size-5"
                        matPrefix
                        [svgIcon]="'heroicons_solid:search'"
                    ></mat-icon>
                    <input
                        matInput
                        (keyup)="applyFilter($event)"
                        [autocomplete]="'off'"
                        [placeholder]="'Search User Name'"
                        [(ngModel)]="filterValueToDisplay"
                    />
                </mat-form-field>
                
            </div>
    </div>
    
    <div class="mat-elevation-z8 mt-3 overflow-auto">
       
        <table mat-table [dataSource]="dataSource" matSort >
            <ng-container matColumnDef="userName" >
                <th mat-header-cell *matHeaderCellDef>User Name</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.user_name}} </td>
            </ng-container>
            <ng-container matColumnDef="email" >
                <th mat-header-cell *matHeaderCellDef >Email</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.email}} </td>
            </ng-container>
            <ng-container matColumnDef="mobileNumber" >
                <th mat-header-cell *matHeaderCellDef >Mobile Number</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.mobile_number}}</td>
            </ng-container>
            <ng-container matColumnDef="planName">
                <th mat-header-cell *matHeaderCellDef >Plan Name (Plan type)</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> 
                    {{row.plan.name}} <span *ngIf="row.plan.plan_type.name">({{row.plan.plan_type.name}})</span> </td>
            </ng-container>
            
            <ng-container matColumnDef="planType">
                <th mat-header-cell *matHeaderCellDef >Plan Type (Sub Type)</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> 
                    {{row.plan.plan_type.name}} <span *ngIf="row.plan.plan_type.plan_subtype">({{row.plan.plan_type.plan_subtype.name}})</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="planCategory">
                <th mat-header-cell *matHeaderCellDef >Plan Category</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.plan.plan_type.category | titlecase}}</td>
            </ng-container>

            <ng-container matColumnDef="totalPrice">
                <th mat-header-cell *matHeaderCellDef >Total Price</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.total_price}} </td>
            </ng-container>

            <ng-container matColumnDef="paymentStatus">
                <th mat-header-cell *matHeaderCellDef >Payment Status</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.payment_status | titlecase}} </td>
            </ng-container>
            
            <ng-container matColumnDef="paymentSuccessAt">
                <th mat-header-cell *matHeaderCellDef>Payment Success At</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.payment_success_at | dateTimeFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef >Plan Start/End Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row?.plan_start_date | dateFormat}}<span > to {{row?.plan_end_date | dateFormat}}</span> </td>
            </ng-container>
            
            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef >Plan End Date</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row?.plan_end_date | dateFormat}}</td>
            </ng-container>

            <ng-container matColumnDef="planStatus">
                <th mat-header-cell *matHeaderCellDef >Plan Status</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row?.plan_status | titlecase}}</td>
            </ng-container>
            


            <ng-container matColumnDef="invoiceNo" >
                <th mat-header-cell *matHeaderCellDef r>Invoice No</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                    <a [href]="row?.invoice_url" target="_blank">{{row?.invoice_no | titlecase}}</a>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="paymentGateway">
                <th mat-header-cell *matHeaderCellDef >Payment Gateway</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row?.payment_gateway?.split('_').join(' ') | titlecase}}</td>
            </ng-container>
            
            <ng-container matColumnDef="Actions" >
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                  <button mat-button [matMenuTriggerFor]="menu" [disabled]="row.is_removed">
                    <mat-icon style="color:#0038FF;font-size: 30px;" svgIcon="heroicons_outline:dots-vertical"></mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToViewSchedules && row?.payment_status === 'SUCCESS'" (click)="getUserPlanSchedules(row)">View Plan Schedules</button>
                    <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToCancelPlans && row?.payment_status === 'SUCCESS' && (row?.plan_status === 'ACTIVE' || row?.plan_status === 'UPCOMING')" (click)="cancelPlanPage(row)">Cancel Plan</button>
                    <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToUpdatePlanTrainer && row?.payment_status === 'SUCCESS' && (row?.plan_status === 'ACTIVE' || row?.plan_status === 'UPCOMING')" (click)="getAvailableTrainersToUpdatePlanTrainer(row)">Update Trainer</button>
                    <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToUpdatePlanTrainer && row?.payment_status === 'SUCCESS' && (row?.plan_status === 'ACTIVE' || row?.plan_status === 'UPCOMING')" (click)="updatePlanTimeTrainer(row)">Update Time Trainer</button>
                    <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToExtendPlans && row?.payment_status === 'SUCCESS' && (row?.plan_status === 'ACTIVE' || row?.plan_status === 'UPCOMING')" (click)="extendPlanPage(row)">Extend Plan</button>
                  </mat-menu>
                </td>
              </ng-container>
              

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>
    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>