<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="row mt-8 justify-content-between">
            <div class="col-sm-5 col-md-4 col-lg-2">
              <h1 class="h3 ml-4 mr-md-6 mr-sm-1 heading">
                BLOOD&nbsp;WORK
              </h1>
            </div>
            <div class="col-sm-6 col-md-4  d-flex justify-content-end" *ngIf="isUserAllowedToBloodWorkCreate">
                <button class="ml-4 addBtn" [ngClass]="{'btn-clr': true}" mat-flat-button [color]="'primary'" (click)="createBloodWorkStat()">
                    <mat-icon class="white-icon" [svgIcon]="'heroicons_outline:plus'"></mat-icon>
                    <span class="ml-2 mr-1 txt" style="color: white">Create</span>
                </button>
            </div>
        </div>


<div class="row">
    <div class="col-sm-12 col-md-4 col-lg-2">
        <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 100%;" [subscriptSizing]="'dynamic'">
            <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:search'"></mat-icon>
            <input matInput (keyup)="applyFilter($event)" [autocomplete]="'off'" [(ngModel)]="filterValue"
                    [placeholder]="'Search Device Name'">
        </mat-form-field>
    </div>
</div>

<div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="userName">
                <th mat-header-cell *matHeaderCellDef>User Name</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.user.full_name}} </td>
            </ng-container>
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef>Category</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.category | titlecase}} </td>
            </ng-container>
            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef >Value</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.value}} </td>
            </ng-container>
            <ng-container matColumnDef="collectedDate">
                <th mat-header-cell *matHeaderCellDef >Collected At</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row"> {{row.collected_at | dateTimeFormat}} </td>
            </ng-container>
            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef>Created By</th>
                <td mat-cell  [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row">
                    {{row.created_by?.full_name}}<span *ngIf="row.created_by?.role">({{row.created_by.role.name}})</span> 
                </td>
            </ng-container>

            <!-- <ng-container matColumnDef="Actions">
                <th class="text-center" mat-header-cell *matHeaderCellDef mat-sort-header >Actions</th>
                <td mat-cell [ngClass]="{'delete-records-style': row.is_removed}" *matCellDef="let row "> 
                    <button mat-button [matMenuTriggerFor]="menu" [disabled]="!isUserAllowedToDeviceEdit && !isUserAllowedToDeviceDeactivate && !isUserAllowedToDeviceReactivate"><mat-icon style="color:#0038FF;font-size: 30px;" [disabled]="(isUserAllowedToDeviceEdit && isUserAllowedToDeviceDeactivate && isUserAllowedToDeviceReactivate)"
                            svgIcon="heroicons_outline:dots-vertical"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToDeviceEdit" (click)="editDevice(row)">Edit</button>
                        <button mat-menu-item *ngIf="!row.is_removed && isUserAllowedToDeviceDeactivate" (click)="verifyDeactivate(row.id)">Deactivate</button>
                        <button mat-menu-item *ngIf="row.is_removed && isUserAllowedToDeviceReactivate" (click)="reactivateDevice(row.id)">Reactivate</button>
                    </mat-menu>
                </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- Row shown when there is no matching data. -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>
        </table>
    </div>

    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons>
    </mat-paginator>
</div>
