<div class="container-fluid">
    <div>
        <div class="d-flex m-3">
            <div class=" mr-2">
                <button mat-icon-button (click)="back()">
                    <mat-icon style="color: #00FF00;" svgIcon="feather:arrow-left-circle"></mat-icon>
                </button>
            </div>
            <h2 class="h3 createHeading">{{pageTitle}}</h2>
        </div>

        <div class="card" [formGroup]="createBloodWorkForm" style="background-color: #FFFFFF;height: auto;padding: 10px; ">
            <div class="mt-2 ml-3 mb-4">
                <h4 style="color: #00FF00;">
                    Create Blood Work
                </h4>
                <div class="row mr-3 ml-3">
                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                            Category
                            <span class="text-danger">*</span>
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <mat-select id="category_type" name="Category Type" required [placeholder]="'Category Type'" formControlName="category">
                                    <mat-option *ngFor="let categoryType of categoryTypes" [value]="categoryType.value">{{categoryType.viewValue}}</mat-option>
                                </mat-select>
                                <mat-error *ngIf="createBloodWorkForm.get('category').hasError('required')">
                                    Category is required
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-sm-12 col-md-12 col-lg-4">
                        <div class="fw-bold fieldName">
                            Value
                        </div>
                        <div class="">
                            <mat-form-field class="w-full">
                                <input id="value" style="background: #FFFFFF;" matInput
                                   placeholder="Enter Value" formControlName="value">
                                <mat-error *ngIf="createBloodWorkForm.get('value').hasError('required')">
                                    Value is required
                                </mat-error>
                                <mat-error *ngIf="createBloodWorkForm.get('value').hasError('pattern')">
                                    Value should be valid number
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-4">
                                <div class="fw-bold fieldName">
                                    Branch
                                </div>
                                <div class="" >
                                    <mat-form-field class="flex-auto gt-xs:pr-3 w-full"  >
                                      <mat-select [placeholder]="'All Branches'" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedBranchIds" (ngModelChange)="getUsers()" multiple>
                                        <mat-checkbox class="" [checked]="allBranchesChecked" [indeterminate]="someBranchesChecked()" (change)="setAllBranchesChecked($event)">
                                            Select All
                                        </mat-checkbox>
                                        <mat-option *ngFor="let branch of branchIdNameList" [value]="branch.id">{{branch.name | titlecase  }}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                   
                                  </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-4">
                                <div class="fw-bold fieldName">
                                   Role
                                </div>
                                <div class="" >
                                    <mat-form-field class="flex-auto gt-xs:pr-3 w-full"  >
                                      <mat-select [placeholder]="'All Roles'" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedroleIds" (ngModelChange)="getUsers()" multiple>
                                        <mat-checkbox class="" [checked]="allRolesChecked" [indeterminate]="someRolesChecked()" (change)="setAllRolesChecked($event)">
                                            Select All
                                        </mat-checkbox>
                                        <mat-option *ngFor="let role of roleIdNameList" [value]="role.id">{{role.name | titlecase }}</mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                  </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-4">
                                <div class="fw-bold fieldName">
                                    User
                                </div>
                                <div class="">
                                    <mat-form-field class="flex-auto gt-xs:pr-3 w-full" >
                                      <mat-select [placeholder]="'All Users'" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedUserId">
                                        <div style="display: flex;justify-content: flex-start; align-items: center;">
                                            <i class="material-icons mr-1">search</i>
                                            <input matInput (keyup)="applyFilterOnUsers($event.target.value)">
                                        </div>
                                        <mat-option *ngFor="let user of filteredUserIdNameList" [value]="user.id">{{user.full_name | titlecase }}<span>({{user.mobile_number}})</span></mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="m-2 btnposition">
        <div class="bottomBtns mb-3 row">
          <button class="ml-4 addBtn" (click)="reset()"mat-flat-button>
            <span class="ml-2 mr-1 txt">Reset</span>
          </button>
          <button class="ml-4 mr-6 addBtn"  mat-flat-button [color]="'primary'"
         (click)="createBloodWorkAPI()"  [disabled]="!isBloodWorkDataValid()" [ngClass]="{'btn-clr': isBloodWorkDataValid()}">
            <span class="ml-2 mr-1 txt" style="color: white">Create </span>
          </button>
        </div>
      </div>
</div>