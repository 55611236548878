import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator,PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import moment from 'moment';
import { IReassignmentsActions, IRescheduleActions} from 'app/meta.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reassignments',
  templateUrl: './reassignments.component.html',
  styleUrls: ['./reassignments.component.scss']
})
export class ReassignmentsComponent {

  scrHeight: number;
  scrWidth: number;

  searchTerm = null;
  selectedRescheduleRequestStatus = null;

  dataSource = new MatTableDataSource<Element>();
  searchInputControl: UntypedFormControl = new UntypedFormControl()
  
  displayedColumns = ["User", "OldTrainer", "NewTrainer", "SessionDateTime", "ReasonType", "Status"];
 
  IReschedule: IReassignmentsActions = {
    processStatus: "",
    reasonType: "",
  }
  reassignment_reason_type = [
    { value: 'TRAINER_NO_SHOW', viewValue: 'Trainer No Show' },
  ]
  statusList= [
    { value: 'INITIATED', viewValue: 'Initiated' },
    { value: 'SENT_TRAINER_ALERT1', viewValue: 'Sent Trainer Alert 1' },
    { value: 'SENT_TRAINER_ALERT2', viewValue: 'Sent Trainer Alert 2' },
    { value: 'SENT_TRAINER_NO_SHOW_CONFIRMATION_TO_CUSTOMER', viewValue: 'Sent Trainer No Show Confirmation to Customer' },
    { value: 'CUSTOMER_AWARE_OF_TRAINER_STATUS', viewValue: 'Customer Aware of Trainer Status' },
    { value: 'CUSTOMER_NOT_AWARE_OF_TRAINER_STATUS', viewValue: 'Customer Not Aware of Trainer Status' },
    { value: 'REASSIGNMENT_REQUEST_SENT_TO_OTHER_TRAINERS', viewValue: 'Reassignment Request Sent to Other Trainers' },
    { value: 'REASSIGNMENT_PROCESS_DONE', viewValue: 'Reassignment Process Done' },
    { value: 'CANCEL', viewValue: 'Cancel' }
  ]
  totalCount = 0;
  pageIndex = 0;
  pageSize = 10
  bOffset:number= 0;
  bLimit :number=10;
  filterValue= null;
  filterValueToDisplay=""
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public apiService: ApiService, public toastr: ToastrService, public router: Router) {
    this.getScreenSize();
  }
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    // This is get ad on every time screen is resized
  }

  ngOnInit() {
    const paginationDataString = sessionStorage.getItem('paginationDataForReschedulerequests');
    sessionStorage.removeItem('paginationData');
    sessionStorage.removeItem('paginationDataForBranch');
    sessionStorage.removeItem('paginationDataForDevice');
    sessionStorage.removeItem('paginationDataForLeave');
    sessionStorage.removeItem('paginationDataForUserPurchaseHistory');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('paginationDataForUser');
    sessionStorage.removeItem('paginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForPlan');
    sessionStorage.removeItem('paginationDataForScheduleList');
    sessionStorage.removeItem('PaginationDataForPlanSubType');
    sessionStorage.removeItem('paginationDataForCoupon');
    sessionStorage.removeItem('paginationDataForTimeslotDiscount');
    
    if (paginationDataString) {
      const paginationData = JSON.parse(paginationDataString);
      this.pageIndex = paginationData.pageIndex;
      this.bOffset = paginationData.bOffset;
      this.selectedRescheduleRequestStatus=paginationData.filterByRescheduleRequestsAlias;
      this.IReschedule.processStatus=paginationData.filterByRescheduleBookingStatuses;
      // this.IReschedule.reasonType=paginationData.filterByRescheduleBookingStatuses;
      this.filterValue=paginationData.filterValue;
      this.filterValueToDisplay=paginationData.filterValue;
    }
    this.getAllRescheduleRequests()
  }
  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.onGetAllRescheduleRequestsForSearch()
  }
  getAllRescheduleRequests() {

    let filterData = {
      filter_by_reassignment_reason_type: this.IReschedule.reasonType,
      filter_by_reassignment_process_status: this.IReschedule.processStatus,
      offset:this.bOffset,
      limit:this.bLimit,
      search_term:this.filterValue || null
    }
    this.apiService.getReassignmentsByFilter(filterData).subscribe((response) => {
      if (response.status == "success") {
        this.dataSource.data = response.data.reassignment_requests.map(reassignment_data => {
          return {
            "rescheduleSlotBookingId": reassignment_data.id,
            "user": reassignment_data.reassigned_from_slot.user.full_name,
            "old_trainer": reassignment_data.reassigned_from_slot.trainer.full_name,
            "new_trainer": reassignment_data?.reassigned_to_slot?.trainer?.full_name ? reassignment_data.reassigned_to_slot.trainer.full_name : '' ,
            "session_date_time": reassignment_data.reassigned_from_slot.start_date_time,
            // "session_date": moment(new Date(reassignment_data.reassigned_from_slot.start_date_time)).format("YYYY-MM-DD").toString(),
            "reason_type": reassignment_data.reassignment_reason_type.split('_').join(' '),
            "status": reassignment_data.reassignment_process_status.split('_').join(' '),
          }
        });
        this.totalCount=response.data.total_count;
      }
    },
      (err) => {
        this.toastr.error(err.error.message)
      }
    ); 
  }
  updateReschedule(row) {
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByRescheduleRequestsAlias: this.selectedRescheduleRequestStatus,
      filterByRescheduleBookingStatuses: this.IReschedule.processStatus,
      filterValue:this.filterValue
    };
    sessionStorage.setItem('paginationDataForReschedulerequests', JSON.stringify(paginationData));
    sessionStorage.setItem('rescheduleSlotIdForUpdateSchedule', row.rescheduleSlotBookingId);
    this.router.navigate(['Admin/edit-reschedule'])
  }
  onPaginateChange(event: PageEvent): void {

    this.bLimit = event.pageSize;
    this.bOffset = (event.pageIndex) * event.pageSize;
    this.pageIndex = event.pageIndex;
  
  
    this.getAllRescheduleRequests(); 
  }

  onGetAllRescheduleRequestsForSearch(){
    this.bOffset=0;
    this.pageIndex=0;
   
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByRescheduleRequestsAlias: this.selectedRescheduleRequestStatus,
      filterByRescheduleBookingStatuses: this.IReschedule.processStatus,
      filterValue:this.filterValue
    };
    sessionStorage.setItem('paginationDataForReschedulerequests', JSON.stringify(paginationData));
    this.getAllRescheduleRequests()
  }

  onGetAllRescheduleRequests(){
    this.bOffset=0;
    this.pageIndex=0;
    this.filterValue=null;
    const paginationData = {
      pageIndex: this.pageIndex,
      bOffset: this.bOffset,
      filterByRescheduleRequestsAlias: this.selectedRescheduleRequestStatus,
      filterByRescheduleBookingStatuses: this.IReschedule.processStatus,
      filterValue:this.filterValue
    };
    sessionStorage.setItem('paginationDataForReschedulerequests', JSON.stringify(paginationData));
    this.getAllRescheduleRequests()
  }

}
