
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from './constants';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return super.transform(value, DATE_FORMAT);
  }
}

@Pipe({
    name: 'timeFormat'
  })
  export class TimeFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
      return super.transform(value, TIME_FORMAT);
    }
}
  
@Pipe({
    name: 'dateTimeFormat'
  })
  export class DateTimeFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
      return super.transform(value, DATE_TIME_FORMAT);
    }
  }

  @Pipe({
    name: 'sentenceWithUnderscoreTitleCase'
  })
  export class SentenceWithUnderscoreTitleCase extends TitleCasePipe implements PipeTransform {
    transform(value: any, args?: any): any {
      value.split('_').join(' ')
      return super.transform(value);
    }
  }
  