import { Component, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'app/api-services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-blood-work-list',
  templateUrl: './blood-work-list.component.html',
  styleUrls: ['./blood-work-list.component.scss']
})
export class BloodWorkListComponent {
  
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  displayedColumns = ['userName', 'category', 'value', 'collectedDate', 'createdBy'];
  dataSource = new MatTableDataSource<Element>();

  filterValue=null;
  totalCount: number = 0;
  pageIndex: number = 0;
  pageSize: number = 10
  bOffset: number = 0;
  bLimit: number = 10;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  // userAllowedFeatures = getuserAllowedFeatures()
  //   isUserAllowedToViewBranches = this.userAllowedFeatures.indexOf(featureConstants.VIEW_BRANCHES) > -1
  isUserAllowedToBloodWorkCreate = true

  constructor(
    private router: Router, 
    // public dialog: MatDialog,
    // public meta: MetaService, 
    public apiService: ApiService,
    private toastr: ToastrService
  ){

  }

  ngOnInit() {
    this.filterBloodWorkStats()
  }

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.filterBloodWorkStats()
  }

  onPaginateChange(event: PageEvent): void {
      this.bLimit = event.pageSize;
      this.bOffset = (event.pageIndex) * event.pageSize;
      this.pageIndex = event.pageIndex;
    
      this.filterBloodWorkStats(); 
    }

  filterBloodWorkStats(){

    let filterData = {
      filter_by_search_term: this.filterValue || null,
      limit: this.bLimit,
      offset: this.bOffset,
    }

    this.apiService.getAllBloodWorkStats(filterData).subscribe((resp) => {
      if (resp.status == "success") {
        this.dataSource.data = resp.data.bloodwork_stats;
        this.totalCount = resp.data.total_count
      }
    },
    (err) => {
      this.toastr.error(err.error.message)
    });
  }

  createBloodWorkStat(){
    this.router.navigateByUrl('Admin/create-blood-work-stat');
  }
}
