<!-- <p>roles-list works!</p> -->
<div class="container-fluid">
    <div class="row">
        <div class="row mt-8 justify-content-between">
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-2">
            <h1 class="h3 ml-4 mr-2 heading">Reassignments</h1>
            </div>
    </div>


    <div class="row">
        <div class="col-sm-12 col-md-4 col-lg-2">
            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                    <mat-select [placeholder]="'All'" [(ngModel)]="IReschedule.reasonType" (selectionChange)="onGetAllRescheduleRequests()">
                      <mat-option value="">All</mat-option>
                      <mat-option *ngFor="let status of reassignment_reason_type" [value]="status.value">{{ status.viewValue}}</mat-option>
                    </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-2">
            <mat-form-field class="flex-auto gt-xs:pr-3" style="width: 90%;">
                    <mat-select [placeholder]="'All'" [(ngModel)]="IReschedule.processStatus" (selectionChange)="onGetAllRescheduleRequests()">
                      <mat-option value="">All</mat-option>
                      <mat-option *ngFor="let status of statusList" [value]="status.value">{{ status.viewValue}}</mat-option>
                    </mat-select>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-2 mb-2">
            <mat-form-field
                class="flex-auto gt-xs:pr-3"
                style="width: 90%"
                [subscriptSizing]="'dynamic'"
            >
                <mat-icon
                    class="icon-size-5"
                    matPrefix
                    [svgIcon]="'heroicons_solid:search'"
                ></mat-icon>
                <input
                    matInput
                    (keyup)="applyFilter($event)"
                    [autocomplete]="'off'"
                    [placeholder]="'Search User Name'"
                    [(ngModel)]="filterValueToDisplay"
                />
            </mat-form-field>
        </div>
            
    </div>
    
    <div class="mat-elevation-z8 mt-2 overflow-auto">
        <table mat-table [dataSource]="dataSource" matSort>

            <ng-container matColumnDef="User">
                <th mat-header-cell *matHeaderCellDef >User</th>
                <td mat-cell *matCellDef="let row"> {{row.user | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="OldTrainer">
                <th mat-header-cell *matHeaderCellDef >Old Trainer</th>
                <td mat-cell *matCellDef="let row"> {{row.old_trainer | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="NewTrainer">
                <th mat-header-cell *matHeaderCellDef >New Trainer</th>
                <td mat-cell *matCellDef="let row"> {{row.new_trainer | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="SessionDateTime">
                <th mat-header-cell *matHeaderCellDef >Session Date Time</th>
                <td mat-cell *matCellDef="let row"> {{row.session_date_time | dateTimeFormat}} </td>
            </ng-container>

            <ng-container matColumnDef="ReasonType">
                <th mat-header-cell *matHeaderCellDef >Reason Type</th>
                <td mat-cell *matCellDef="let row"> {{row.reason_type | titlecase}} </td>
            </ng-container>

            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef >Status</th>
                <td mat-cell *matCellDef="let row"> {{row.status | titlecase}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <!-- when there is no matching data it will be visible -->
            <div>
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="12">
                        <h3 style="display: flex;justify-content: center; margin:10%;">
                            No Records Found
                        </h3>
                    </td>
                </tr>
            </div>
        </table>

    </div>
    <mat-paginator style="display:flex;justify-content: end;" 
    (page)="onPaginateChange($event)" [length]="totalCount"
    [pageIndex]="pageIndex" [pageSize]="pageSize" [pageSizeOptions]="[10, 25, 100]"
    aria-label="Select page of users" showFirstLastButtons></mat-paginator>
</div>
